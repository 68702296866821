import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostListener, OnInit, inject, signal } from '@angular/core';
import { toSignal } from '@angular/core/rxjs-interop';
import { RippleModule } from 'primeng/ripple';
import { FallbackImgDirective, isLockedSignal, isUserLoggedInSignal } from 'src/app/shared';
import { ToastController, NavController, ModalController } from '@ionic/angular/standalone';
import { UserStore } from 'src/app/store';

import { Router } from '@angular/router';
import { UnifiedUser } from 'src/app/domain';
import { AuthService } from 'src/app/services/apis';
import { IdleService } from 'src/app/services';
import { Capacitor } from '@capacitor/core';
import { Haptics, ImpactStyle } from '@capacitor/haptics';

@Component({
  selector: 'app-pin',
  template: `
    <div class="p-4 h-screen overflow-auto flex flex-col items-center justify-center">
      <div class="flex flex-col items-center justify-center">
        <div class="w-full max-w-md flex items-center justify-center mt-16">
          <img
            appFallbackImg
            src="assets/icons/svg/auth/verify-account.svg"
            alt="logo"
            priority
            width="100"
            height="100" />
        </div>

        <p class="w-72 text-center text-gray-800 text-[32px] font-bold font-['Roboto'] leading-[44px] mt-8">
          Enter Your Pin
        </p>

        <p class="w-72 text-center text-gray-800 text-[16px] font-['Roboto'] leading-[24px] mt-4">
          {{ user()?.email }}
        </p>

        <button class="mt-4  p-2" (click)="signOut()">Log out</button>

        <div class="flex flex-row items-center justify-between w-full gap-2 mt-8">
          <div
            class="w-12 h-12 bg-gray-100 rounded-full border-2 border-green-500 justify-center items-center gap-2.5 inline-flex text-primary-900 font-bold"
            [ngClass]="{ 'border-3 border-primary-800': currentIndex() === 0 }">
            {{ pins()[0] !== '' ? '*' : '' }}
          </div>
          <div
            class="w-12 h-12 bg-gray-100 rounded-full border-2 border-green-500 justify-center items-center gap-2.5 inline-flex text-primary-900 font-bold"
            [ngClass]="{ 'border-3 border-primary-800': currentIndex() === 1 }">
            {{ pins()[1] !== '' ? '*' : '' }}
          </div>
          <div
            class="w-12 h-12 bg-gray-100 rounded-full border-2 border-green-500 justify-center items-center gap-2.5 inline-flex text-primary-900 font-bold"
            [ngClass]="{ 'border-3 border-primary-800': currentIndex() === 2 }">
            {{ pins()[2] !== '' ? '*' : '' }}
          </div>
          <div
            class="w-12 h-12 bg-gray-100 rounded-full border-2 border-green-500 justify-center items-center gap-2.5 inline-flex text-primary-900 font-bold"
            [ngClass]="{ 'border-3 border-primary-800': currentIndex() === 3 }">
            {{ pins()[3] !== '' ? '*' : '' }}
          </div>
          <div
            class="w-12 h-12 bg-gray-100 rounded-full border-2 border-green-500 justify-center items-center gap-2.5 inline-flex text-primary-900 font-bold"
            [ngClass]="{ 'border-3 border-primary-800': currentIndex() === 4 }">
            {{ pins()[4] !== '' ? '*' : '' }}
          </div>
          <div
            class="w-12 h-12 bg-gray-100 rounded-full border-2 border-green-500 justify-center items-center gap-2.5 inline-flex text-primary-900 font-bold"
            [ngClass]="{ 'border-3 border-primary-800': currentIndex() === 5 }">
            {{ pins()[5] !== '' ? '*' : '' }}
          </div>
        </div>
      </div>

      <div class="w-full h-[50dvh] grid grid-cols-3 grid-rows-4 gap-3 mt-8 p-8 lg:w-[300px] lg:h-[300px]">
        <div pRipple class="num-pad " (click)="setValue('1')">1</div>
        <div pRipple class="num-pad " (click)="setValue('2')">2</div>
        <div pRipple class="num-pad " (click)="setValue('3')">3</div>
        <div pRipple class="num-pad " (click)="setValue('4')">4</div>
        <div pRipple class="num-pad " (click)="setValue('5')">5</div>
        <div pRipple class="num-pad " (click)="setValue('6')">6</div>
        <div pRipple class="num-pad " (click)="setValue('7')">7</div>
        <div pRipple class="num-pad " (click)="setValue('8')">8</div>
        <div pRipple class="num-pad " (click)="setValue('9')">9</div>
        <div pRipple class="num-pad ">
          <!-- <img appFallbackImg src="assets/icons/svg/biometric.svg" class="w-12 h-12" /> -->
        </div>
        <div pRipple class="num-pad " (click)="setValue('0')">0</div>
        <div pRipple class="num-pad " (click)="removeValue()">
          <img appFallbackImg src="assets/icons/svg/backspace.svg" class="w-12 h-12" />
        </div>
      </div>
    </div>
  `,
  standalone: true,
  imports: [RippleModule, CommonModule, FallbackImgDirective],
})
export class PinComponent implements OnInit {
  pins = signal(['', '', '', '', '', '']);
  currentIndex = signal(0);

  userStore = inject(UserStore);
  authService = inject(AuthService);
  router = inject(Router);
  navCtrl = inject(NavController);

  user = toSignal(this.userStore.user$);
  toastCtrl = inject(ToastController);
  destroyRef = inject(DestroyRef);
  idleService = inject(IdleService);
  modalCtrl = inject(ModalController);

  pinCounter = 0;

  // Listening to key presses
  @HostListener('window:keydown', ['$event'])
  handleKeyDown(event: KeyboardEvent) {
    const { key } = event;

    // Handle only numeric input and backspace
    if (/^[0-9]$/.test(key)) {
      this.setValue(key);
    } else if (key === 'Backspace') {
      this.removeValue();
    }
  }

  ngOnInit() {
    this.currentIndex.set(0);
    this.pins.set(['', '', '', '', '', '']);
  }

  async setValue(value: string) {
    if (Capacitor.isNativePlatform()) {
      await Haptics.impact({
        style: ImpactStyle.Light,
      });
    }

    this.pins.update((pins) => {
      pins[this.currentIndex()] = value;
      return pins;
    });
    if (this.currentIndex() < this.pins().length - 1)
      this.currentIndex.update((value) => {
        if (value < this.pins().length - 1) return value + 1;
        else return value;
      });
    else this.validatePin();
  }

  removeValue() {
    this.pins.update((pins) => {
      pins[this.currentIndex()] = '';
      return pins;
    });
    if (this.currentIndex() > 0)
      this.currentIndex.update((value) => {
        if (value > 0) return value - 1;
        else return value;
      });
  }

  async validatePin() {
    if (this.pinCounter >= 3) {
      this.router.navigate(['/auth/login']);
    }

    const pin = this.pins().join('');
    const unlock = (entity: UnifiedUser) => {
      this.idleService.updateLastInteraction();
      this.idleService.dismissModal.next();
      //this.userStore.getProfile();
      isLockedSignal.set(false);
      this.modalCtrl.dismiss();
    };
    if (pin === this.user()?.pin) {
      this.userStore.updateEntity({
        id: this.user()?.id!,
        entity: { ...this.user()!, isActive: true },
        callback: (entity) => unlock(entity),
      });

      isUserLoggedInSignal.set(true);
    } else if (pin == this.user()?.secondPin) {
      this.userStore.updateEntity({
        id: this.user()?.id!,
        entity: { ...this.user()!, isActive: false },
        callback: (entity) => unlock(entity),
      });
      isUserLoggedInSignal.set(true);
    } else {
      const toast = await this.toastCtrl.create({
        message: 'Pin is not valid, please try again',
        duration: 2000,
        position: 'bottom',
        color: 'danger',
        buttons: [
          {
            text: 'Ok',
            role: 'cancel',
          },
        ],
      });
      toast.present();
      this.pins.update((pins) => {
        pins = ['', '', '', '', '', ''];
        return pins;
      });
      this.currentIndex.set(0);
      this.pinCounter++;
    }
  }

  async signOut() {
    await this.navCtrl.navigateRoot('auth/login');

    const signOut = new Promise<void>((resolve) => {
      this.userStore.signOut({
        callback: async () => {
          this.modalCtrl.dismiss();
          await this.authService.signOut();
          window.location.reload();
          resolve();
        },
      });
    });

    await signOut;
  }
}
